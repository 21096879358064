import { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Amplify from "@aws-amplify/core";
import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import { Backdrop, Button, Container, Fade, Modal } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import "../css/App.css";
import theme from "./theme";
import awsconfig from "../aws-exports";
import OTP from "./otp";
import Survey from "./survey";
import Invitation from "./invitation";
import Countdown from "./countdown";
import Itinerary from "./itinerary";

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [hasReadInvitation, setHasReadInvitation] = useState(false);
  const [answers, setAnswers] = useState(null);
  const [fetchedAnswers, setFetchedAnswers] = useState(false);
  const [rsvpModalOpen, setRsvpModalOpen] = useState(false);

  useEffect(() => {
    verifyAuth();
  }, []);

  const getAnswers = (phone) => {
    API.get("theyselim30Api", `/guests/${phone}`, {})
      .then((result) => {
        setAnswers(result[0]);
        setFetchedAnswers(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setSession(null);
        getAnswers(user.username);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOpenRsvpModal = () => {
    setRsvpModalOpen(true);
  };

  const handleCloseRsvpModal = () => {
    setRsvpModalOpen(false);
  };

  const isSignedUpAndOpenedInvite = user && fetchedAnswers;
  const isJoiningTheParty = answers?.rsvp === "true";

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={5}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <h3 className="shimmer">
                Thirty.
                {/* {isSignedUpAndOpenedInvite && hasReadInvitation && (
                  <>
                    <div className="pyro">
                      <div className="before"></div>
                      <div className="after"></div>
                    </div>
                  </>
                )} */}
              </h3>
            </Grid>
            {!user && (
              <Grid item xs={12}>
                {/* Handles OTP login and displays greeting if user is auth-ed */}
                <OTP
                  session={session}
                  setSession={setSession}
                  setUser={setUser}
                  user={user}
                  getAnswers={getAnswers}
                />
              </Grid>
            )}

            {!hasReadInvitation && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {user && fetchedAnswers && (
                  <Invitation
                    user={user}
                    answers={answers}
                    setHasReadInvitation={setHasReadInvitation}
                  />
                )}
              </Grid>
            )}

            {isSignedUpAndOpenedInvite && hasReadInvitation && (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Date: Aug. 14, 2021 6:30 PM - Location: NYC
                  </Typography>
                </Grid>

                {isJoiningTheParty && (
                  <>
                    <Grid item xs={12}>
                      <Itinerary />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOpenRsvpModal}
                      >
                        Change RSVP
                      </Button>
                      <Modal
                        closeAfterTransition
                        aria-labelledby="Change RSVP"
                        open={rsvpModalOpen}
                        onClose={handleCloseRsvpModal}
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Fade in={rsvpModalOpen}>
                          <Survey user={user} answers={answers} />
                        </Fade>
                      </Modal>
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {isSignedUpAndOpenedInvite &&
              !isJoiningTheParty &&
              hasReadInvitation && (
                <Grid
                  container
                  className="content"
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  {/* Survey questions */}
                  <Survey user={user} answers={answers} />
                </Grid>
              )}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;

import {
  Avatar,
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { AttachMoneyOutlined, InfoOutlined } from "@material-ui/icons";
import SailingBoat from "mdi-material-ui/SailBoat";
import FoodTurkey from "mdi-material-ui/FoodTurkey";
import City from "mdi-material-ui/CityVariantOutline";
import { useState } from "react";
import Boat from "../util/boat.jpg";
import BoatLocation from "../util/boat-location.jpg";
import MonarchRooftop from "../util/monarch.jpg";
import CasaDinner from "../util/casa.jpg";
import Venmo from "../util/venmo.jpg";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
}));

const Itinerary = () => {
  const classes = useStyles();
  const [boatingModalOpen, setBoatingModalOpen] = useState(false);
  const [dinnerModalOpen, setDinnerModalOpen] = useState(false);
  const [rooftopModalOpen, setRooftopModalOpen] = useState(false);
  const [venmoModalOpen, setVenmoModalOpen] = useState(false);

  const closeBoatingModal = () => {
    setBoatingModalOpen(false);
  };

  const openBoatingModal = () => {
    setBoatingModalOpen(true);
  };

  const closeDinnerModal = () => {
    setDinnerModalOpen(false);
  };

  const openDinnerModal = () => {
    setDinnerModalOpen(true);
  };

  const closeRooftopModal = () => {
    setRooftopModalOpen(false);
  };

  const openRooftopModal = () => {
    setRooftopModalOpen(true);
  };

  const closeVenmoModal = () => {
    setVenmoModalOpen(false);
  };

  const openVenmoModal = () => {
    setVenmoModalOpen(true);
  };

  return (
    <>
      <List dense={false}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <SailingBoat />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="6:30 PM - Boating with Manhattan By Sail"
            secondary="Your ticket has been booked! Please see additional details for time, location, and venmo info."
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="view-more"
              onClick={openBoatingModal}
            >
              <InfoOutlined />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <FoodTurkey />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="9:45 PM - Dinner at Casa La Femme"
            secondary="Please see additional details for time, location, and cover info."
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="view-more"
              onClick={openDinnerModal}
            >
              <InfoOutlined />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <City />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="11:45 PM - Party at Monarch Rooftop"
            secondary="Please see additional details for time, location and cover info."
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="view-more"
              onClick={openRooftopModal}
            >
              <InfoOutlined />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Modal
        closeAfterTransition
        open={boatingModalOpen}
        onClose={closeBoatingModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fade in={boatingModalOpen}>
          <Card
            style={{
              width: 275,
              wordWrap: "normal",
            }}
          >
            <CardHeader
              avatar={
                <Avatar>
                  <SailingBoat />
                </Avatar>
              }
              title="Sunset Cruise NYC - Shearwater"
              subheader="7:00 PM - 8:45 PM @ North Cove Marina at Brookfield Place"
            />
            <CardMedia
              className={classes.media}
              image={Boat}
              title="Classic Schooner"
            />
            <CardMedia
              className={classes.media}
              image={BoatLocation}
              title="Location"
            />
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Make sure you are at the dock at 6:30 PM. The boat leaves
                promptly and no refunds for missed sailings.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Alcoholic and non-alcoholic beverages are available. You can
                bring your own food on the boat.
              </Typography>
              <Typography variant="body1">
                Please venmo $67.5 for the ticket or $135 if you have a +1.
              </Typography>
            </CardContent>

            <CardActions>
              <Button size="small" color="primary" onClick={openVenmoModal}>
                Venmo
              </Button>
              <Button
                size="small"
                color="primary"
                href="https://www.manhattanbysail.com/shearwater/sunset-sail/"
                target="_blank"
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>

      <Modal
        closeAfterTransition
        open={dinnerModalOpen}
        onClose={closeDinnerModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fade in={dinnerModalOpen}>
          <Card
            style={{
              width: 275,
              wordWrap: "normal",
            }}
          >
            <CardHeader
              avatar={
                <Avatar>
                  <FoodTurkey />
                </Avatar>
              }
              title="Dinner at Casa La Femme"
              subheader="9:45 - 11:00 PM @ Greenwich Village"
            />
            <CardContent>
              <CardMedia
                className={classes.media}
                image={CasaDinner}
                title="Casa La Femme"
              />
              <Typography variant="body2" gutterBottom>
                Make sure you are at the restaurant at 9:45 PM. The reservation
                is only held for 10 minutes.
              </Typography>
              <Typography variant="body2" gutterBottom>
                3 Course menu is covered under the minimum charge for the party,{" "}
                <Link
                  href="https://www.casalafemmeny.com/55-large-party-3-course-prix-fix-menu.html"
                  target="_blank"
                >
                  see menu
                </Link>
                . For additional food/drinks,{" "}
                <Link
                  href="https://www.casalafemmeny.com/current-menu.html"
                  target="_blank"
                >
                  see regular menu
                </Link>
                .
              </Typography>
              <Typography variant="body1">
                Minimum per person is $86 (this includes 3 courses, gratuity &
                tax). Please venmo $86 or $172 if you have a +1.
              </Typography>
            </CardContent>

            <CardActions>
              <Button size="small" color="primary" onClick={openVenmoModal}>
                Venmo
              </Button>
              <Button
                size="small"
                color="primary"
                href="https://www.google.com/maps/place/Casa+La+Femme/@40.734151,-74.0167852,15z/data=!4m5!3m4!1s0x89c259eb60fa33e3:0xf2998f687fe33ca9!8m2!3d40.734147!4d-74.0080251"
                target="_blank"
              >
                Location
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>

      <Modal
        closeAfterTransition
        open={rooftopModalOpen}
        onClose={closeRooftopModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fade in={rooftopModalOpen}>
          <Card
            style={{
              width: 275,
              wordWrap: "normal",
            }}
          >
            <CardHeader
              avatar={
                <Avatar>
                  <City />
                </Avatar>
              }
              title="Party at Monarch Rooftop"
              subheader="11:45 PM - 1:45 AM @ Midtown"
            />
            <CardContent>
              <CardMedia
                className={classes.media}
                image={MonarchRooftop}
                title="rooftop"
              />
              <Typography variant="body2" gutterBottom>
                Make sure you are at the rooftop at 11:45 PM. The reservation is
                only held for 15 minutes.
              </Typography>
              <Typography variant="body2" gutterBottom>
                The cover charge takes care of birthday stuff: cup cakes,
                birthday shots (wink wink), and a fine vodka bottle for the
                table.
              </Typography>
              <Typography variant="body1">
                Cover per person is $66.67. Please venmo $66.67 or $133.34 if
                you have a +1.
              </Typography>
            </CardContent>

            <CardActions>
              <Button size="small" color="primary" onClick={openVenmoModal}>
                Venmo
              </Button>
              <Button
                size="small"
                color="primary"
                href="https://g.page/MonarchRooftop?share"
                target="_blank"
              >
                Location
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>

      {/* VENMO Modal */}
      <Modal
        open={venmoModalOpen}
        onClose={closeVenmoModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card>
          <CardHeader
            avatar={
              <Avatar>
                <AttachMoneyOutlined />
              </Avatar>
            }
            title="Venmo Information"
            subheader="Reach out to me if you don't have Venmo"
          />
          <CardMedia
            className={classes.media}
            style={{ paddingTop: "105%" }}
            image={Venmo}
            title="Venmo"
          />
        </Card>
      </Modal>
    </>
  );
};

export default Itinerary;

import moment from "moment";
import { useState, useEffect } from "react";
import { describeArc } from "../util/svgCircleHelpers";

const Countdown = () => {
  const [timerState, setTimer] = useState({});

  useEffect(() => {
    setInterval(() => {
      const then = moment("2021-08-14 12:00 am", "YYYY-MM-DD  h:mm a");
      const now = moment();

      const days = then.diff(now, "days");
      const hours = then.diff(now, "hours") - days * 24;
      const minutes = then.diff(now, "minutes") - days * 24 * 60 - hours * 60;
      const seconds =
        then.diff(now, "seconds") -
        days * 24 * 60 * 60 -
        hours * 60 * 60 -
        minutes * 60;

      setTimer({ days, hours, minutes, seconds });
    }, 1000);
  }, []);

  const { days, hours, minutes, seconds } = timerState;

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    return null;
  }

  return (
    <div className="countdown-wrapper">
      {days && days > 0 && (
        <div className="countdown-item">
          {days}
          <span>days</span>
        </div>
      )}
      {hours && hours > 0 && (
        <div className="countdown-item">
          {hours}
          <span>hours</span>
        </div>
      )}
      {minutes && minutes > 0 && (
        <div className="countdown-item">
          {minutes}
          <span>minutes</span>
        </div>
      )}
      {seconds && seconds > 0 && (
        <div className="countdown-item">
          {seconds}
          <span>seconds</span>
        </div>
      )}
    </div>
  );
};

export default Countdown;

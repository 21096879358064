import Typewriter from "typewriter-effect";
import greeting from "./greeting";

const Invitation = ({ setHasReadInvitation, user, answers }) => {
  // Seen invite already
  if (!answers) {
    return (
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .typeString(`${greeting} ${user.attributes.name}`)
            .pauseFor(2500)
            .deleteAll()
            .typeString(
              `You're invited to my 30th birthday on Saturday, Aug. 14th`
            )
            .pauseFor(300)
            .deleteChars(22)
            .typeString("in NYC!")
            .pauseFor(300)
            .deleteChars(7)
            .typeString("on a boat")
            .pauseFor(300)
            .typeString(`.... and more?`)
            .pauseFor(500)
            .deleteAll()
            .typeString("More to follow ;)")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Cheers! - Y. S.")
            .pauseFor(300)
            .callFunction(() => setHasReadInvitation(true))
            .start();
        }}
      />
    );
  }

  return (
    <Typewriter
      onInit={(typewriter) => {
        typewriter
          .typeString(
            `Welcome back ${user.attributes.name}! ${
              answers?.rsvp === "true"
                ? "Excited to see you Aug. 14th :)"
                : ""
            }`
          )
          .pauseFor(300)
          .callFunction(() => setHasReadInvitation(true))
          .start();
      }}
    />
  );
};

export default Invitation;

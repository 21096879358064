import Auth from "@aws-amplify/auth";
import { useState } from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import spinner from "../util/spinner.svg";
import { InfoOutlined } from "@material-ui/icons";

const VERIFYNUMBER = "Verifying number...";
const WAITING = "Waiting on ya...";

const OTP = ({ setSession, getAnswers, setUser, session, user }) => {
  const [otp, setOtp] = useState("");
  const [number, setNumber] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  // const password = Math.random().toString(10) + "Abc#";

  // const signUp = async (numberWithCode) => {
  //   const result = await Auth.signUp({
  //     username: numberWithCode,
  //     password,
  //     attributes: {
  //       name: "Joe",
  //       phone_number: numberWithCode,
  //     },
  //   })
  //   return result;
  // };

  const signIn = () => {
    setMessage(VERIFYNUMBER);
    setShowSpinner(true);

    const numberWithCode = number.startsWith("+") ? number : `+1${number}`;
    Auth.signIn(numberWithCode)
      .then((result) => {
        setSession(result);
        setMessage(WAITING);
      })
      .catch((e) => {
        if (e.code === "UserNotFoundException") {
          // signUp(numberWithCode);
        } else if (e.code === "UsernameExistsException") {
          setMessage(WAITING);
          setTimeout(() => signIn(), 1500);
        } else {
          console.log(e.code);
          console.error(e);
        }

        setShowSpinner(false);
        setMessage("");
      });
  };

  const verifyOtp = () => {
    Auth.sendCustomChallengeAnswer(session, otp)
      .then((user) => {
        setUser(user);
        setSession(null);
        getAnswers(user.username);
        setMessage("");
      })
      .catch((err) => {
        if (err.code === "NotAuthorizedException") {
          setMessage("Incorrect code");
        }

        setOtp("");
        console.log(err);
      });
    setShowSpinner(false);
  };

  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {!user && !session && message !== VERIFYNUMBER && (
        <>
          <Grid container direction="row" spacing={2} alignItems="flex-end">
            <Grid item xs={8}>
              <TextField
                id="standard-basic"
                label="Enter your phone number"
                onChange={(event) => setNumber(event.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" color="primary" onClick={signIn}>
                Verify
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button color="primary" size="small" onClick={openPopover}>
              What's happening with my phone number?
            </Button>
          </Grid>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography style={{whiteSpace: 'normal'}} className={classes.typography}>
              Your phone number is ONLY used to verify access beyond this page.
              By continuing you will receive a verification code to your phone
              number by SMS. Message and data rates may apply.
            </Typography>
          </Popover>
        </>
      )}

      {!user && session && (
        <Grid container direction="row" spacing={2} alignItems="flex-end">
          <Grid item xs={8}>
            <TextField
              id="standard-basic"
              label="Enter code"
              onChange={(event) => setOtp(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" color="primary" onClick={verifyOtp}>
              Continue
            </Button>
          </Grid>
        </Grid>
      )}

      <>
        {message && (
          <Grid container>
            {showSpinner && <img alt="loading spinner" src={spinner} />}
            <Typography variant="body2" gutterBottom>
              {message}

              {/* Display info icon when the message is waiting for code to educate on international code */}
              {message === WAITING && (
                <>
                  <IconButton aria-label="info" onClick={openPopover}>
                    <InfoOutlined />
                  </IconButton>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Typography className={classes.typography}>
                      Use +20 for EG numbers. US and Canada are automatically
                      recognized
                    </Typography>
                  </Popover>
                </>
              )}
            </Typography>
          </Grid>
        )}
      </>
    </>
  );
};

export default OTP;

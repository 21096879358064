/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7c8c2059-c1a0-4194-a293-bf9f2dd0b428",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_NJanvk6Km",
    "aws_user_pools_web_client_id": "tu7tjkeirghjd7b8dg48ejefs",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "theyselim30DB-dev",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "theyselim30Api",
            "endpoint": "https://0m3y8jm48i.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;

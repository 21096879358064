import { useEffect, useState } from "react";
import { API } from "aws-amplify";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { addGuestInformation } from "./api";
import spinner from "../util/spinner.svg";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 275,
    wordWrap: "normal",
  },
}));

const Survey = ({ user, answers }) => {
  const classes = useStyles();

  const [updating, setUpdating] = useState(false);
  const [btnText, setConfirmBtnText] = useState("Confirm Changes");

  const [rsvp, setRsvpValue] = useState(answers?.rsvp || null);
  const [plusOne, setPlusOneValue] = useState(answers?.plusOne || null);
  const [boating, setBoatingValue] = useState(answers?.boating || null);
  const [rooftop, setRooftopValue] = useState(answers?.rooftop || null);
  const [after, setAfterValue] = useState(answers?.after || null);

  const [afterEvents, setAfterEvents] = useState(
    answers?.afterEvents || {
      hookah: false,
      clubbing: false,
      speakeasy: false,
      bowling: false,
    }
  );

  const { username } = user;

  useEffect(() => {
    // new guest
    // if (!answers) {
    //   addGuestInformation(username);
    // }
  }, []);

  const onUpdateClick = (event) => {
    event.stopPropagation();
    setUpdating(true);

    API.put("theyselim30Api", `/guests`, {
      body: {
        phone: username,
        rsvp,
        plusOne,
        boating,
        rooftop,
        after,
        afterEvents,
      },
    })
      .then((result) => {
        setUpdating(false);
        setConfirmBtnText("Changes updated!");
        console.log(result);
      })
      .catch((err) => {
        setUpdating(false);
        console.log(err);
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container direction="row" justify="center" alignItems="center">
          <Questions
            setRsvpValue={setRsvpValue}
            setPlusOneValue={setPlusOneValue}
            setBoatingValue={setBoatingValue}
            setRooftopValue={setRooftopValue}
            setAfterValue={setAfterValue}
            setAfterEvents={setAfterEvents}
            rsvp={rsvp}
            plusOne={plusOne}
            after={after}
            boating={boating}
            rooftop={rooftop}
            afterEvents={afterEvents}
          />
        </Grid>
      </CardContent>
      <CardActions>
        {updating && (
          <>
            <img alt="loading spinner" src={spinner} />
            <Typography variant="caption" display="block" gutterBottom>
              Updating...
            </Typography>
          </>
        )}
        {!updating && (
          <Button
            size="small"
            color="primary"
            onClick={onUpdateClick}
            disabled={btnText === "Changes updated!"}
          >
            {btnText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const Questions = ({
  setRsvpValue,
  setPlusOneValue,
  setBoatingValue,
  setRooftopValue,
  setAfterValue,
  setAfterEvents,
  rsvp,
  after,
  plusOne,
  boating,
  rooftop,
  afterEvents,
}) => {
  const onRsvpChange = (event) => {
    setRsvpValue(event.target.value);
  };

  const onPlusOneChange = (event) => {
    setPlusOneValue(event.target.value);
  };

  const onBoatingChange = (event) => {
    setBoatingValue(event.target.value);
  };

  const onRooftopChange = (event) => {
    setRooftopValue(event.target.value);
  };

  const onAfterEventChange = (event) => {
    setAfterValue(event.target.value);
  };

  const onAfterEventsChange = (event) => {
    setAfterEvents({
      ...afterEvents,
      [event.target.name]: event.target.checked,
    });
  };

  const { speakeasy, bowling, hookah, clubbing } = afterEvents;

  return (
    <>
      <Grid item xs={4}>
        <FormLabel component="legend">RSVP</FormLabel>
      </Grid>
      <Grid item xs={8}>
        <RadioGroup
          row
          aria-label="rsvp"
          name="rsvp"
          value={rsvp}
          onChange={onRsvpChange}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="No"
          />
        </RadioGroup>
      </Grid>

      {rsvp === "true" && (
        <>
          <Grid item xs={4}>
            <FormLabel component="legend">+1</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-label="Plus One"
              name="plusOne"
              value={plusOne}
              onChange={onPlusOneChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </Grid>

          <FormControl component="fieldset">
            <FormLabel component="legend">
              Are you interested in joining a rental sail boat before sunset?*
              (~6:30 PM)
            </FormLabel>
            <RadioGroup
              row
              name="boating"
              value={boating}
              onChange={onBoatingChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">
              Will you join the rooftop dinner/drinks party? (~10PM)
            </FormLabel>
            <RadioGroup
              row
              name="rooftop"
              value={rooftop}
              onChange={onRooftopChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">
              Are you interested in joining a late night event? (~12AM)
            </FormLabel>
            <RadioGroup
              row
              name="afterEvent"
              value={after}
              onChange={onAfterEventChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

          {after === "true" && (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Pick the ones you would join
              </FormLabel>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bowling}
                      onChange={onAfterEventsChange}
                      name="bowling"
                      color="primary"
                    />
                  }
                  label="Bowling"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={clubbing}
                      onChange={onAfterEventsChange}
                      name="clubbing"
                      color="primary"
                    />
                  }
                  label="Clubbing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hookah}
                      onChange={onAfterEventsChange}
                      name="hookah"
                      color="primary"
                    />
                  }
                  label="Hookah Lounge"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={speakeasy}
                      onChange={onAfterEventsChange}
                      color="primary"
                      name="speakeasy"
                    />
                  }
                  label="Speakeasy"
                />
              </FormGroup>
              <FormHelperText>
                *The boat is $67.5 for early reservations, and $75+tax afterwards.
              </FormHelperText>
            </FormControl>
          )}
        </>
      )}
    </>
  );
};

export default Survey;

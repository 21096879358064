const today = new Date()
const curHr = today.getHours()

let greeting = 'Hey';

if (curHr < 12) {
    greeting = 'Good morning';
} else if (curHr < 18) {
    greeting = 'Good afternoon';
} else {
    greeting = 'Good evening';
}

export default greeting;
